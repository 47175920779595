import { useFlags } from "launchdarkly-react-client-sdk";

const doesElementHaveLink = (target, currentTarget) => {
  if (target && target.parentNode !== currentTarget) {
    if (target.href) {
      return target;
    } else {
      return doesElementHaveLink(target.parentNode, currentTarget);
    }
  }
  return;
};

export function useDocumentItemExternalLink() {
  const { releaseUrlsForCustomDocumentDescriptions = false } = useFlags();

  function handleClickExternalLink(e) {
    e.preventDefault();
    const element = doesElementHaveLink(e.target, e.currentTarget);

    if (element?.href) {
      window.open(element.href, "_blank");
    }
  }

  return {
    handleClickExternalLink,
    flag: releaseUrlsForCustomDocumentDescriptions,
  };
}

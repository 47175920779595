import { useState } from "react";
import { routes } from "routes";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useTranslation, Trans } from "react-i18next";

import { useSelectedFleet } from "hooks/useSelectedFleet";
import { useFeatureFlagsFromSearchParams } from "hooks/useFeatureFlagsFromSearchParams";

import { Banner } from "components/Banner";
import {
  Gallery,
  GalleryCaption,
  GalleryImage,
  GalleryItem,
  GalleryModal,
} from "components/Gallery";

import { NewDocumentStatusIcon, DocumentStatus } from "../documents";
import { Details } from "./components/Details";

import styles from "./Faqs.module.scss";

function useFaq() {
  const { t } = useTranslation();
  const { isNewDocumentStatusIconsEnabled } = useFeatureFlagsFromSearchParams();

  const newAnswer4 = !isNewDocumentStatusIconsEnabled;

  return [
    {
      question: t("faq.question1"),
      answer: t("faq.answer1"),
    },

    {
      question: t("faq.question2"),
      answer: t("faq.answer2", {
        resetPasswordLink: window.origin.concat(routes.forgetPassword.getURL()),
      }),
      images: [
        {
          src: "/sign-in-page.png", // file is in the `/public` directory
          caption: t("faq.signInPageLabel"),
        },
      ],
    },

    {
      question: t("faq.question3"),
      answer: t("faq.answer3"),
    },

    {
      question: t("faq.question4"),
      rich: newAnswer4,
      answer: newAnswer4 ? t("faq.answer4newDocumentStatus") : t("faq.answer4"),
    },
  ];
}

export const FaqPage = () => {
  const { t } = useTranslation();
  const faq = useFaq();

  const [currentImage, setCurrentImage] = useState("");

  const { selectedFleet } = useSelectedFleet();

  return (
    <div className={styles.contactWrapper}>
      <Banner
        headingLevel="h2"
        headingText={t("pageFaq.contacts")}
        description={t("pageFaq.contactToReportIssues")}
      />
      <span className={styles.contactLinks}>
        <Banner
          className={styles.bannerWrapper}
          headingLevel="h3"
          headingText={t("field.email")}
          description={selectedFleet?.email || t("pageFaq.noEmail")}
        />
        <Banner
          headingText="Phone number"
          headingLevel="h3"
          description={selectedFleet?.phone_number || t("pageFaq.noPhone")}
          className={styles.bannerWrapper}
        />
      </span>

      <div style={{ marginTop: 8 }}>
        {faq.map(({ question, answer, images, rich }, index, array) => (
          <Details
            key={question}
            label={question}
            first={index === 0}
            last={index === array.length - 1}
          >
            {rich ? (
              <Trans
                i18nKey={answer}
                components={{
                  h3: <h3 />,
                  table: <table />,
                  tbody: <tbody />,
                  tr: <tr />,
                  td: <td style={{ padding: 4 }} />,
                  uptodateicon: (
                    <NewDocumentStatusIcon
                      status={DocumentStatus.UP_TO_DATE}
                      inline
                    />
                  ),
                  expiringicon: (
                    <NewDocumentStatusIcon
                      status={DocumentStatus.EXPIRING}
                      inline
                    />
                  ),
                  expiredicon: (
                    <NewDocumentStatusIcon
                      status={DocumentStatus.EXPIRED}
                      inline
                    />
                  ),
                  inreviewicon: (
                    <NewDocumentStatusIcon
                      status={DocumentStatus.IN_REVIEW}
                      inline
                    />
                  ),
                  absenticon: (
                    <NewDocumentStatusIcon
                      status={DocumentStatus.ABSENT}
                      inline
                    />
                  ),
                }}
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: answer }}></div>
            )}

            {Array.isArray(images) ? (
              <div className={styles.faqPageGalleryWrapper}>
                <Gallery>
                  {images.map(({ src, caption }) => {
                    return (
                      <GalleryItem key={src}>
                        <GalleryImage
                          src={src}
                          onClick={() => setCurrentImage(src)}
                        />
                        <GalleryCaption>{caption}</GalleryCaption>
                      </GalleryItem>
                    );
                  })}
                </Gallery>

                <GalleryModal
                  visible={currentImage.length > 0}
                  currentImage={currentImage}
                  onCancel={() => setCurrentImage("")}
                />
              </div>
            ) : null}
          </Details>
        ))}
      </div>
    </div>
  );
};

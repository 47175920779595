import { useState, useEffect } from "react";
import clsx from "clsx";
import { Row, Col } from "antd";

import { useSelectedFleet } from "hooks/useSelectedFleet";
import { useFeatureFlagsFromSearchParams } from "hooks/useFeatureFlagsFromSearchParams";

import { WalletBalanceForm } from "./BalancePayoutComp";
import { Earnings } from "./Earnings";
import { LastPayoutWidget } from "./LastPayoutWidget";
import { PayoutsWidget } from "./PayoutsWidget";

import "./Home.scss";
import styles from "./Home.module.scss";

const DISABLE_SCHEDULING_TIMEOUT = 5 * 1000;

export const HomePageInternal = () => {
  const { selectedDriverProfile } = useSelectedFleet();

  const {
    isPayoutsTableOnHomePageEnabled: payoutsTableEnabledFromSearchParams,
  } = useFeatureFlagsFromSearchParams();

  const [schedulePayoutsRefresh, setSchedulePayoutsRefresh] = useState(false);

  useEffect(
    function disableSchedulingAfterTimeout() {
      if (!schedulePayoutsRefresh) return;

      const timeout = setTimeout(() => {
        setSchedulePayoutsRefresh(false);
      }, DISABLE_SCHEDULING_TIMEOUT);

      return () => {
        clearTimeout(timeout);
      };
    },
    [schedulePayoutsRefresh, setSchedulePayoutsRefresh]
  );

  return (
    <div className={clsx("home-wrapper", styles.homeWrapper)}>
      <div className={styles.balanceWrapper}>
        <Row gutter={[24, 24]} className="balance-payout-wrapper">
          <Col span={24}>
            <WalletBalanceForm
              driverProfile={selectedDriverProfile}
              setSchedulePayoutsRefresh={setSchedulePayoutsRefresh}
            />
          </Col>

          {payoutsTableEnabledFromSearchParams ? (
            <LastPayoutWidget driverProfile={selectedDriverProfile} />
          ) : (
            <PayoutsWidget schedulePayoutsRefetch={schedulePayoutsRefresh} />
          )}
        </Row>
      </div>
      <div className={styles.earningsWrapper}>
        <Earnings driverProfileId={selectedDriverProfile?.id} />
      </div>
    </div>
  );
};
